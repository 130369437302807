@tailwind components;

@layer components {


  .mobile-nav-container {
		@apply sm:hidden mt-4;
	}

	.mobile-nav-position {
		@apply z-50 w-[250px];
	}

  .mobile-nav-focus-logo {
		@apply pr-1 h-32 w-96;
	}
}
