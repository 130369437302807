@tailwind components;
@import '../base/colors';

@layer components
{
  .find-help-input {
    @apply transition-all py-4 sm:w-[600px] w-[24rem]
  }

  input, textarea {
    background-color: $light-yellow;
  }
}
