@tailwind components;

@layer components
{
  .card-article {
    @apply transition-all text-left sm:p-4 p-8 rounded-lg text-black shadow-[0_0px_1px_1px_rgba(0,0,0,0.1)] sm:w-60 w-96 text-center
  }

  .card-program {
    @apply transition-all text-left sm:rounded-lg text-black shadow-[0_0px_1px_1px_rgba(0,0,0,0.1)] sm:w-80 w-96 text-center
  }

  .card-program img {
    @apply sm:rounded-t-lg
  }

  .card-podcast {
    @apply transition-all text-left text-black border border-salmon shadow-[0_0px_1px_1px_rgba(0,0,0,0.1)] sm:w-64 w-96 text-center
  }
}
