@tailwind components;

.mat-mdc-text-field-wrapper {
  padding-left: 0px !important;
}

.mat-mdc-form-field-bottom-align::before{
  height: 0px !important;
  display: none !important;
}

.mdc-text-field--no-label:not(.mdc-text-field--outlined):not(.mdc-text-field--textarea) .mat-mdc-form-field-infix {
  padding-bottom: 0px !important;
}

.mat-datepicker-actions {
  justify-content: space-evenly !important;
}

.mat-datepicker-toggle > button {
  padding: 0px !important;
  width: 28px !important;
  height: 20px !important;
}

.mat-datepicker-toggle > button > span {
  width: 20px !important;
  height: 20px !important;
  top: -3px !important;
}

.mat-mdc-text-field-wrapper.mdc-text-field.mdc-text-field--filled.mdc-text-field--no-label {
  background-color: white;
  height: 50px;
}

.mat-mdc-form-field-infix {
  padding-top: 10px !important;
}

.mat-form-field-appearance-outline.mat-form-field-disabled.mat-form-field-outline {
  opacity: 0;
}