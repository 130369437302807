@tailwind components;

@layer components
{
  .btn-dark-blue {
    @apply transition-all rounded-lg bg-dark-blue text-white border border-dark-blue;
  }

  .btn-salmon {
    @apply transition-all rounded-lg bg-salmon text-black border border-salmon;
  }

  .btn-white {
    @apply transition-all rounded-lg bg-white text-dark-blue border border-dark-blue;
  }

  .btn-white-strong {
    @apply transition-all rounded-lg bg-white text-dark-blue border border-2 border-dark-blue font-semibold;
  }

  .btn-yellow {
    @apply transition-all rounded-lg bg-yellow text-black border border-yellow;
  }

  .btn-help-yellow {
    @apply transition-all rounded-lg bg-yellow text-black border border-yellow py-4 px-8;
  }

  .btn-yellow-blue {
    @apply transition-all rounded-lg bg-yellow text-dark-blue border border-yellow shadow-md font-medium;
  }

  .btn-blue-underline {
    @apply transition-all text-dark-blue underline font-bold hover:text-light-blue;
  }

  .btn-red {
    @apply transition-all rounded-lg bg-dark-red text-white border border-dark-red hover:bg-red shadow-md font-medium;
  }

  *:disabled {
    cursor: not-allowed;
    opacity: 50%;
  }
}
