
// Custom Theming for Angular Material
// For more information: https://material.angular.io/guide/theming
@use '@angular/material' as mat;
// Plus imports for other components in your app.

// Include the common styles for Angular Material. We include this here so that you only
// have to load a single css file for Angular Material in your app.
// Be sure that you only ever include this mixin once!
@include mat.core();

// Define the palettes for your theme using the Material Design palettes available in palette.scss
// (imported above). For each palette, you can optionally specify a default, lighter, and darker
// hue. Available color palettes: https://material.io/design/color/
$sjc-admin-primary: mat.define-palette(mat.$indigo-palette);
$sjc-admin-accent: mat.define-palette(mat.$pink-palette, A200, A100, A400);

// The warn palette is optional (defaults to red).
$sjc-admin-warn: mat.define-palette(mat.$red-palette);

// Create the theme object. A theme consists of configurations for individual
// theming systems such as "color" or "typography".
$sjc-admin-theme: mat.define-light-theme((
  color: (
    primary: $sjc-admin-primary,
    accent: $sjc-admin-accent,
    warn: $sjc-admin-warn,
  )
));

// Include theme styles for core and each component used in your app.
// Alternatively, you can import and @include the theme mixins for each component
// that you are using.
@include mat.all-component-themes($sjc-admin-theme);

/* You can add global styles to this file, and also import other style files */
@tailwind base;
@tailwind components;
@tailwind utilities;
@import "./scss/base/module";
@import "./scss/components/module";

html, body { height: 100%; background-color: #FEFAF5; }

.mat-mdc-snack-bar-container.success-snackbar{
  --mdc-snackbar-container-color: #3E5B6B !important;
  border-radius: 4px;
}
.mat-mdc-snack-bar-container.error-snackbar{
  --mdc-snackbar-container-color: #602020 !important;
  border-radius: 4px;
}

.mat-mdc-snack-bar-container.info-snackbar{
  --mdc-snackbar-container-color: #4D4D4D !important;
  border-radius: 4px;
}

.sjc-tooltip {
  white-space: pre-line !important;
}

#role .mat-mdc-select {
  height: 42px;
  background-color: white;
  border-width: 1px;
  border-color: rgb(71 71 71);
}

#role div {
  height: 100%;
}

#role .mat-mdc-select-value {
  display: flex;
  align-items: center;
}
