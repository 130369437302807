$salmon: #F3DCD4;
$light-blue: #409BEE;
$blue: #4F6D7D;
$dark-blue: #3E5B6B;
$yellow: #FFEBA9;
$light-yellow: #FEFAF5;
$orange: #D78468;
$dark-orange: #A77969;
$grey: #737373;
$dark-grey: #4D4D4D;
$black: #1F1F1F;
$white: #FFFFFF;
$red: #B91C1C;
$dark-red: #602020;
